import { forwardRef } from 'react'
import { TooltipContentProps } from '@radix-ui/react-tooltip'
import Button, { ButtonProps } from '@/components/button'
import { cls } from '@/utils'
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from '@/components/ui/tooltip'

export interface IconButtonProps extends ButtonProps {
  label?: string
  hasArrow?: boolean
  sideOffset?: TooltipContentProps['sideOffset']
  side?: TooltipContentProps['side']
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      label,
      className,
      hasArrow,
      sideOffset = 8,
      side = 'top',
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              {...props}
              ref={ref}
              className={cls('', className)}
              aria-label={label}
            >
              {children}
            </Button>
          </TooltipTrigger>
          <TooltipContent
            className={cls(
              'flex flex-col gap-1 w-max max-w-50 text-left text-text-on-color',
            )}
          >
            {label}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  },
)

IconButton.displayName = 'IconButton'

export default IconButton
