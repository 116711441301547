'use client'

import Upload from '@/components/upload'
import { IMAGE_ACCEPT } from '@/constants'
import { buildFileFromSample, TemplateWidgetProps } from './common'
import { cls, whisper } from '@/utils'
import { useCallback, useMemo, useState } from 'react'
import Tooltip from '@/components/tooltip'
import Button from '@/components/button'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { UploadSample } from '@/components/gs-upload/sample-dialog'
import Dialog from '@/components/dialog'
import Guidance from '@/components/guidance'
import Image from '@/components/image'

export default function ModelImage({ inputWidget, templateInputs, setTemplateInputs }: TemplateWidgetProps) {
  const { key, name, default_value, type, widget, samples, privateProps } = inputWidget ?? {}
  const [mode, setMode] = useState<'custom' | 'sample'>('sample')
  const modeOptions = useMemo(() => {
    return [
      { value: 'sample', label: 'Recommended' },
      { value: 'custom', label: 'Custom' },
    ] as const
  }, [])

  const handleValueChange = useCallback(
    (value: any) => {
      setTemplateInputs?.((prev) => ({
        ...prev,
        [key]: value,
        input_model_mask_top_image_url: undefined,
        input_model_mask_bottom_image_url: undefined,
        input_model_mask_suit_image_url: undefined,
        input_model_is_recommended: false,
      }))
    },
    [key, setTemplateInputs],
  )

  const handlePickSample = useCallback(
    async (sample: UploadSample) => {
      const file = await buildFileFromSample(sample, 'image')
      setTemplateInputs?.((prev) => ({
        ...prev,
        ...sample?.extra,
        [inputWidget.key]: file,
      }))
    },
    [setTemplateInputs, inputWidget],
  )

  return (
    <div className='flex flex-col gap-2 items-center w-70 h-[310px]'>
      <div className='relative w-full h-5 flex justify-center items-center gap-1'>
        <span className='text-body-md font-medium tracking-15'>{name}</span>
        <Dialog
          asChild
          title='Guideline for Model Images'
          footer={null}
          className='w-full md:w-[544px]'
          trigger={
            <Button
              variant='transparent'
              className='grow-0 shrink-0 p-0 h-5 max-w-5 hover:bg-transparent active:bg-transparent'
            >
              <IconInfo className='size-5 text-icon-subdued hover:opacity-80' />
            </Button>
          }
        >
          <div className=''>
            <Guidance
              className=''
              rules={[
                'Single character in Full-body or half-body photo, front-view, with a plain background.',
                'Simple pose, arms relaxed, unobstructed, good lighting.',
              ]}
              goodExamples={[
                'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-30-df3386c1.webp',
                'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-33-8990b0c2.webp',
                'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-36-65740477.webp',
                'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-38-217ec19f.webp',
              ]}
              badExamples={[
                {
                  url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-19-cbcfd4e2.webp',
                  description: 'Group people',
                },
                {
                  url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-22-295be831.webp',
                  description: 'Hands covered',
                },
                {
                  url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-25-02f61f9e.webp',
                  description: 'Composite img',
                },
                {
                  url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-27-6bcad5df.webp',
                  description: 'Complex pose',
                },
              ]}
              content='The image must be 3:4, ideally 768 × 1024 or larger, short side ≥512px, long side ≤4096px. ≤20MB in JPG, PNG, or WebP.'
            />
          </div>
        </Dialog>
      </div>
      <div className='w-full'>
        <RadioGroup
          className='flex items-center gap-6 py-1'
          value={mode}
          onValueChange={(v) => {
            setMode(v as any)
            handleValueChange(undefined)
          }}
        >
          {modeOptions.map((option) => {
            const id = `template-control-model-mode-${option.value}`
            return (
              <div key={option.value} className='flex gap-2 items-center cursor-pointer'>
                <RadioGroupItem key={option.value} id={id} value={option.value} checked={mode === option.value} />
                <label htmlFor={id} className='text-body-lg cursor-pointer'>
                  {option.label}
                </label>
              </div>
            )
          })}
        </RadioGroup>
      </div>
      {mode === 'custom' && (
        <div className='w-full flex flex-col gap-2'>
          <Upload
            key={inputWidget.key}
            accept={IMAGE_ACCEPT}
            maxFileSize={20 * 1024 * 1024}
            file={templateInputs?.[key] ?? null}
            fileType='image'
            className={cls('border w-full h-32')}
            emptyText={
              <div className='flex flex-col items-center text-center'>
                <span className='text-body-md tracking-15'>Upload image</span>
                <span className='text-body-sm text-text-subdued'>JPG/PNG/WebP, 512px ≤ side ≤ 4096px</span>
              </div>
            }
            onChange={handleValueChange}
            {...privateProps}
          />
          {/* <TemplateSample
            className='w-full'
            samples={samples}
            inputWidget={inputWidget}
            setTemplateInputs={setTemplateInputs}
          /> */}
        </div>
      )}
      {mode === 'sample' && (
        <div className='w-full grid grid-cols-4 gap-2'>
          {samples?.map((sample) => {
            const selected = templateInputs?.[key]?.url === sample.url
            return (
              <div
                key={sample.url}
                className={cls(
                  'overflow-hidden rounded-md border p-0.5 cursor-pointer',
                  selected && 'border-border-active border-2 p-px',
                )}
                aria-label='quick sample'
                onClick={() => handlePickSample(sample)}
              >
                <Tooltip
                  asChild
                  trigger={<Image src={sample.url} alt={sample.name} className='w-full h-full object-top rounded-sm' />}
                  className='p-0 bg-transparent border-0 border-none'
                >
                  <Image src={sample.url} alt={sample.name} className='w-[150px] object-top rounded-sm' />
                </Tooltip>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
