import Button from '@/components/button'
import { useCallback, useState, useMemo } from 'react'
import { cls } from '@/utils'
import Upload from '@/components/upload'
import IconAIStar from '@haiper/icons-svg/icons/solid/ai-star.svg'
import { Accept } from 'react-dropzone'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import Tooltip from '@/components/tooltip'
import UploadSampleDialog from '@/components/gs-upload/sample-dialog'
import { IMAGE_ACCEPT, MAX_IMAGE_FILE_SIZE, MAX_VIDEO_FILE_SIZE, VIDEO_ACCEPT } from '@/constants'
import SamInput, { SamInputData } from '@/components/sam-input'
import useActiveTemplateInputs from '@/hooks/useActiveTemplateInputs'
import { Textarea } from '@/components/creation-input/textarea'
import { buildFileFromSample, TemplateWidgetProps } from './common'
import ModelImage from './model'
import Garment from './garment'

export default function TemplateWidget({ inputWidget, templateInputs, setTemplateInputs }: TemplateWidgetProps) {
  const { key, name, default_value, type, widget, samples, privateProps } = inputWidget ?? {}
  const { data: activeTemplateInputs } = useActiveTemplateInputs()

  const isSam = widget === 'sam'
  const samRelatedField = useMemo(() => {
    if (isSam) {
      return activeTemplateInputs?.find((item) => ['image_input', 'video_input'].includes(item.widget))?.key ?? ''
    }
    return ''
  }, [activeTemplateInputs, isSam])

  const samValue = useMemo(() => {
    if (!isSam) {
      return undefined
    }
    return {
      file: templateInputs?.[samRelatedField],
      sam: templateInputs?.[key],
    }
  }, [samRelatedField, templateInputs, isSam, key])

  const handleSamChange = useCallback(
    (value: SamInputData | undefined) => {
      setTemplateInputs?.((prev) => ({ ...prev, [samRelatedField]: value?.file, [key]: value?.sam }))
    },
    [setTemplateInputs, key, samRelatedField],
  )

  const realSamples = useMemo(() => samples ?? [], [samples])

  const [showSampleDialog, setShowSampleDialog] = useState(false)

  const imageEmptyText = useMemo(() => {
    return (
      <div className='flex flex-col w-full text-body-md font-medium tracking-15'>
        <span>JPEG, PNG, WebP</span>
        <span>≤ 5MB</span>
      </div>
    )
  }, [])

  const videoEmptyText = useMemo(() => {
    return (
      <div className='flex flex-col w-full text-body-md font-medium tracking-15'>
        <span>MP4, MKV, MOV</span>
        <span>≤ 100MB, ≤ 16s</span>
      </div>
    )
  }, [])

  const handleValueChange = useCallback(
    (value: any) => {
      setTemplateInputs?.((prev) => ({ ...prev, [key]: value }))
    },
    [key, setTemplateInputs],
  )

  const sampleButton = (
    <Button
      variant='outline'
      className='w-full h-8 shrink-0 rounded-md'
      disabled={!realSamples?.length}
      onClick={() => setShowSampleDialog(true)}
    >
      <div className='flex items-center gap-1'>
        <IconAIStar className='size-4 text-icon-interactive' />
        <span>Ideas</span>
      </div>
    </Button>
  )

  if (widget === 'image_input' || widget === 'video_input' || widget === 'sam') {
    const fileType = widget === 'image_input' || widget === 'sam' ? 'image' : 'video'

    // const UploadComp = type === 'gcs_key' ? GSUpload : Upload
    const UploadComp = Upload
    const accept: Accept = fileType === 'image' ? IMAGE_ACCEPT : VIDEO_ACCEPT

    return (
      <div className='flex flex-col items-center gap-2'>
        <div className='relative w-full h-5 flex justify-center items-center gap-1'>
          <span className='text-body-md font-medium tracking-15'>{name}</span>
          {(widget === 'image_input' || widget === 'video_input' || widget === 'sam') && (
            <Tooltip
              asChild
              className='bg-surface-on-video border-border text-text-on-color'
              trigger={
                <Button
                  variant='transparent'
                  className='grow-0 shrink-0 p-0 h-5 max-w-5 hover:bg-transparent active:bg-transparent'
                >
                  <IconInfo className='size-5 text-icon-subdued hover:opacity-80' />
                </Button>
              }
            >
              {fileType === 'video' ? videoEmptyText : imageEmptyText}
            </Tooltip>
          )}
        </div>
        {widget === 'image_input' || widget === 'video_input' ? (
          <UploadComp
            key={key}
            accept={accept}
            // maxDuration={MAX_DURATION}
            maxFileSize={widget === 'image_input' ? MAX_IMAGE_FILE_SIZE : MAX_VIDEO_FILE_SIZE}
            file={templateInputs?.[key] ?? null}
            fileType={widget === 'image_input' ? 'image' : 'video'}
            // emptyText={widget === 'image_input' ? imageEmptyText : videoEmptyText}
            className={cls('border')}
            onChange={handleValueChange}
            {...privateProps}
          />
        ) : (
          isSam && (
            <SamInput
              fileType={fileType}
              // tooltips={['Upload Image', 'Click to select']}
              tooltips={null}
              value={samValue}
              onChange={handleSamChange}
              {...privateProps}
            />
          )
        )}
        {widget === 'image_input' || widget === 'video_input' ? sampleButton : null}
        <UploadSampleDialog
          showSearch
          fileType={fileType === 'image' ? 'image' : 'video'}
          open={showSampleDialog}
          title={`Ideas - ${name}`}
          samples={realSamples}
          onOpenChange={() => setShowSampleDialog(false)}
          onCancel={() => setShowSampleDialog(false)}
          onPickSample={async (sample) => {
            const file = await buildFileFromSample(sample, fileType === 'image' ? 'image' : 'video')
            if (inputWidget.widget === 'sam') {
              setTemplateInputs?.((prev) => ({ ...prev, [key]: { file } }))
              handleSamChange({ file, sam: undefined })
            } else {
              setTemplateInputs?.((prev) => ({ ...prev, [key]: file }))
            }
            setShowSampleDialog(false)
          }}
        />
      </div>
    )
  }

  if (widget === 'text_input') {
    return (
      <Textarea
        placeholder='Input your text'
        value={templateInputs?.[key] ?? default_value}
        // className='h-12 min-h-12 rounded-lg border border-b-2 px-3 py-2 align-middle w-full'
        className=''
        onChange={(e) => handleValueChange(e.target.value)}
      />
    )
  }

  if (widget === 'model') {
    return (
      <ModelImage inputWidget={inputWidget} templateInputs={templateInputs} setTemplateInputs={setTemplateInputs} />
    )
  }

  if (widget === 'garment' || widget === ('clothes' as any)) {
    return <Garment inputWidget={inputWidget} templateInputs={templateInputs} setTemplateInputs={setTemplateInputs} />
  }

  return null
}
