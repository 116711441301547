import { InnerUploadFile, SamCallbackData, SamClickType } from '@/types'
import { GSUploadProps } from '../gs-upload'
import { cls, whisper } from '@/utils'
import { useCallback, useState } from 'react'
import { SamWrapper } from '../creation-input/sam-wrapper'
import Upload from '../upload'

export interface SamInputData {
  file?: InnerUploadFile | null
  sam?: SamCallbackData
}

export interface SamInputProps {
  className?: string
  value?: SamInputData
  onChange?: (value?: SamInputData) => void
  fileType: GSUploadProps['fileType']
  tooltips: [string, string] | null
}

export default function SamInput({ className, value, tooltips, fileType, onChange }: SamInputProps) {
  const { file } = value ?? {}
  const activeStep = file ? 'segment' : 'upload'
  const fileId = file?.fileId
  const [uploading, setUploading] = useState(false)

  const handleDeleteFile = useCallback(() => {
    onChange?.()
  }, [onChange])

  const handleFileChange = useCallback(
    (file?: InnerUploadFile | null) => {
      onChange?.({ file })
    },
    [onChange],
  )

  const handleSamChange = useCallback(
    (sam?: SamCallbackData) => {
      if (sam) {
        onChange?.({ file, sam })
      }
    },
    [file, onChange],
  )

  return (
    <div className={cls('flex flex-col gap-2 items-center', className)}>
      <div
        className={cls('text-body-sm flex justify-center items-center gap-2 rounded-md', !tooltips && 'hidden')}
        aria-label='tooltips'
      >
        <div
          className={cls(
            'p-1 rounded-md flex',
            activeStep === 'upload' ? 'text-text-interactive bg-surface-active' : 'text-text-disabled',
          )}
        >
          <span className='px-1'>1.</span>
          {tooltips?.[0] ?? `Upload ${fileType}`}
        </div>
        <div
          className={cls(
            'p-1 rounded-md flex',
            activeStep === 'segment' ? 'text-text-interactive bg-surface-active' : 'text-text-disabled',
          )}
        >
          <span className='px-1'>2.</span>
          {tooltips?.[1] ?? 'Click to selct'}
        </div>
      </div>
      <div
        className={cls(
          'self-start flex md:relative bg-surface w-full shrink-0',
          'flex flex-col justify-center gap-2 min-h-0 md:min-h-[auto]',
        )}
        aria-label='creation-input-fieldset'
      >
        <div className='flex flex-col items-center gap-3'>
          <Upload
            file={file ?? null}
            fileType={fileType}
            className={cls(
              'bg-surface hover:bg-surface-hover rounded-lg border border-solid border-border hover:border-border-hover',
              'md:w-[300px] md:h-[180px] md:mx-auto',
              fileId ? 'hidden' : '',
            )}
            uploading={uploading}
            setUploading={setUploading}
            onChange={handleFileChange}
          />
        </div>
        {fileId ? (
          <SamWrapper
            key={fileId}
            defaultVarying
            hidePrompt
            inputType={fileType}
            className=''
            fileId={fileId}
            url={file?.url ?? ''}
            thumbnailUrl={file?.thumbnailUrl ?? ''}
            varyRegionButtonProps={{ opButtonOnly: true }}
            onChange={handleSamChange}
            onDelete={handleDeleteFile}
          />
        ) : null}
      </div>
    </div>
  )
}
