'use client'

import { SamProps } from '@/types'
import dynamic from 'next/dynamic'
const SamInner = dynamic(() => import('./sam'), {
  ssr: false,
})

export default function Sam(props: SamProps) {
  return <SamInner {...props} />
}
