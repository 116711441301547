import { TemplateInputWidget, InnerUploadFile } from '@/types'
import { Dispatch, SetStateAction, useCallback, useState, useMemo } from 'react'
import { cls, getImageResolution, getVideoResolution, whisper } from '@/utils'
import { UploadSample } from '@/components/gs-upload/sample-dialog'

export interface TemplateWidgetProps {
  inputWidget: TemplateInputWidget
  templateInputs: Record<string, any>
  setTemplateInputs: Dispatch<SetStateAction<Record<string, any>>>
}

export const buildFileFromSample = async (sample: UploadSample, inputType: 'image' | 'video') => {
  const { width, height } =
    inputType === 'image' ? await getImageResolution(sample.url) : await getVideoResolution(sample.url)

  // const thumbnailUrl = inputType === 'image' ? sample.url : (await generateVideoThumbnailFromUrl(sample.url ?? '')).dataUrl
  const thumbnailUrl: string = sample?.thumbnail_url ?? (inputType === 'image' ? sample.url : '')

  const file: InnerUploadFile = {
    url: sample.url,
    thumbnailUrl,
    fileId: sample.file_id,
    thumbnailFileId: '',
    width,
    height,
    status: 'done',
  }
  return file
}
