import { cls } from '@/utils'
import IconCheck from '@haiper/icons-svg/icons/solid/check-radio.svg'
import IconXCircle from '@haiper/icons-svg/icons/solid/x-circle.svg'
import Image from '../image'

export interface GuidanceProps {
  content: string
  rules: string[]
  goodExamples: string[] // image urls
  badExamples: Array<{
    url: string
    description: string
  }>
  className?: string
}

export default function Guidance({ content, goodExamples, badExamples, className, rules }: GuidanceProps) {
  return (
    <div className={cls('flex flex-col', className)}>
      <div className='text-body-md tracking-15 text-text-subdued w-full pt-1'>{content ?? ''}</div>
      <div className='gap-4 flex flex-col w-full pt-6'>
        <div className='w-full flex flex-col gap-2'>
          <div className='text-heading-sm tracking-15 font-bold'>Good Examples</div>
          <div className='flex flex-col gap-2 text-body-sm'>
            {rules.map((rule, index) => {
              return (
                <div key={rule} className='flex gap-1 items-center'>
                  <IconCheck className='size-4 text-icon-success' />
                  <span>{rule}</span>
                </div>
              )
            })}
          </div>
          <div className='w-full grid grid-cols-2 md:grid-cols-4 gap-2'>
            {goodExamples?.map((url, index) => (
              <div key={[url, index].join()} className='border rounded-md p-1 overflow-hidden'>
                <img
                  key={[url, index].join()}
                  src={url}
                  className='w-full h-40 object-cover rounded-[4px]'
                  alt='good example'
                />
              </div>
            ))}
          </div>
        </div>
        <div className='w-full flex flex-col gap-2'>
          <div className='text-heading-sm tracking-15 font-bold'>Bad Examples</div>
          <div className='w-full grid grid-cols-2 md:grid-cols-4 gap-2'>
            {badExamples?.map((item, index) => (
              <div key={[item.url, index].join()} className='border rounded-md p-1 overflow-hidden flex flex-col gap-2'>
                <Image src={item.url} className='w-full h-40 object-cover rounded-[4px]' alt='good example' />
                <div className='flex items-center gap-1'>
                  <IconXCircle className='size-4 shrink-0 text-icon-critical' />
                  <span className='text-body-sm'>{item.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
