import { forwardRef, useCallback } from 'react'
import GSUpload, { GSUploadProps, GSUploadRef } from '@/components/gs-upload'
import { publicUpload } from '@/service/upload.service'
import { UploadParams } from '@/types'
import { nanoid } from 'nanoid'

const Upload = forwardRef<GSUploadRef, GSUploadProps>((props, ref) => {
  const customUpload = useCallback(
    async ({ name, file, onProgress, onSuccess, onError }: UploadParams) => {
      const realFilename = name ?? file.name ?? ''
      const randomId = nanoid()

      const filepath = `${randomId}/${realFilename}`

      try {
        const res = await publicUpload({
          file,
          filepath,
          onProgress,
          onSuccess,
          onError,
        })
        return res
      } catch (error) {
        console.error('upload error', error)
      }
    },
    [],
  )

  return <GSUpload ref={ref} {...props} customUpload={customUpload} />
})

Upload.displayName = 'Upload'

export default Upload
