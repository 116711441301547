import { useMemo } from 'react'
import useActiveTemplate from './useActiveTemplate'
import { TemplateDetail, TemplateInputWidget } from '@/types'

const useActiveTemplateInputs = () => {
  const { data: activeTemplate, ...others } = useActiveTemplate()
  const allInputs: TemplateInputWidget[] = useMemo(
    () => (activeTemplate as TemplateDetail)?.inputs ?? [],
    [activeTemplate],
  )

  const inputs: TemplateInputWidget[] = useMemo(
    () =>
      allInputs.filter((item) => {
        return item.visible !== false
      }),
    [allInputs],
  )

  const defaultValues = useMemo(() => {
    const result: Record<string, any> = {}
    allInputs.forEach((input) => {
      result[input.key] = input.default_value
    })
    return result
  }, [allInputs])

  return {
    ...others,
    data: inputs,
    defaultValues,
  }
}

export default useActiveTemplateInputs
